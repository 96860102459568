import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useCaseStudyQuery = () => {
  const { allSanityCaseStudy } = useStaticQuery(graphql`
    query {
      allSanityCaseStudy(sort: { order: DESC, fields: _createdAt }) {
        caseStudies: nodes {
          body: _rawBody(resolveReferences: { maxDepth: 10 })
          featuredImage {
            ...ImageWithPreview
          }
          slug {
            current
          }
          subtitle
          title
          date(formatString: "DD MMMM YYYY")
          solutionRef {
            title
          }
        }
      }
    }
  `);
  return allSanityCaseStudy || {};
};
