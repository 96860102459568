/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import { Link } from 'gatsby';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { useCaseStudyQuery } from '@querys/useCaseStudyQuery';
import IntroBlock from '@components/other/introBlock';
import ArrowButton from '@components/UI/arrowButton';
import { BackButton, ForwardButton } from '@components/UI/navButtons';

const CaseStudy = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { title, slug, subtitle, body, featuredImage } = page || {};

  const { caseStudies } = useCaseStudyQuery();

  // filter out current listings and slice length to 2
  const relatedCaseStudies = caseStudies
    ?.filter((study) => study?.slug?.current !== slug?.current)
    ?.slice(0, 2);

  const [currentStudy, setCurrentStudy] = useState(0);
  const [nextStudy, setNextStudy] = useState(0);

  useEffect(() => {
    const currentCaseStudyIndex = caseStudies?.findIndex(
      (study) => study?.slug?.current === slug?.current
    );

    setCurrentStudy(currentCaseStudyIndex);
    setNextStudy(
      nextStudy < caseStudies.length ? currentCaseStudyIndex + 1 : 0
    );
  }, [currentStudy]);

  return (
    <Layout reversedHeader={true} disabledHeader={true}>
      <IntroBlock title={title} subtitle={subtitle} />
      <div className='flex-col lg:grid grid-cols-10 gap-x-[58px] px-gutter pt-space-m'>
        <div className='col-start-1 col-end-6 text-grey'>
          <SimplePortableText text={body} />
        </div>
        <SanityImage
          image={featuredImage}
          className='rounded-[50px] col-start-6 col-end-11 mt-20 lg:mt-0'
        />
      </div>

      <div className='blockH3 text-black md:flex justify-between pt-[58px] px-gutter w-full '>
        <BackButton
          linkText='Back to client stories'
          linkUrl='/case-studies'
          extraClasses='w-full'
        />
        <ForwardButton
          linkText='See next'
          linkUrl={`/case-studies/${caseStudies[nextStudy]?.slug?.current}`}
          extraClasses='mt-5 md:mt-0 w-full md:justify-end '
        />
      </div>

      <div className='px-gutter pt-space-m'>
        <h2 className='blockH2'>Similar Client Stories</h2>
        <div className='pt-space-s flex flex-col space-y-[100px]'>
          {relatedCaseStudies?.map((study) => (
            <div className='flex-col lg:grid grid-cols-10 gap-x-[58px] '>
              <Link
                to={`/case-studies/${study?.slug?.current}`}
                className='blockH5 inline-block  col-start-1 col-end-3'
              >
                {' '}
                <ArrowButton linkText='Read more' />
              </Link>
              <div className='col-start-3 col-end-6 '>
                <h2 className='blockH4'>{study?.title}</h2>
                <p className='line-clamp-5 blockH6 text-grey pt-5'>
                  {study?.subtitle}
                </p>
              </div>
              <div className='rounded-[50px] col-start-6 col-end-11 aspect-w-4 aspect-h-3 relative overflow-hidden mr-10 mt-10 lg:mt-0'>
                <SanityImage
                  image={study?.featuredImage}
                  className='absolute inset-0 w-full h-full object-cover'
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
  query standardCaseStudyQuery($slug: String!) {
    page: sanityCaseStudy(slug: { current: { eq: $slug } }) {
      title
      subtitle

      slug {
        current
      }
      featuredImage {
        ...ImageWithPreview
      }
      body: _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;
